import { Divider, Stack, Typography } from '@mui/material';
import React, { Fragment, ReactNode } from 'react';
import StyledLink, { IStyledLinkLinkProps } from '../StyledLink';

import ContactIcons from '../Contact/ContactIcons';
import { MotionContainerStack } from '../Animation/MotionContainerStack';
import ProfilePicture from '../ProfilePicture';
import StyledButton from '../StyledButton';
import StyledNavLink from '../StyledNavLink';
import StyledTypography from '../StyledTypography';
import getVariant from '../Animation/getVariant';
import jobs from '../../data/resume/job';
import { motion } from 'framer-motion';
import personalInfo from '../../data/personalInfo';
import useBreakpoints from '../../hooks/useBreakpoints';
import { useMatch } from 'react-router-dom';

const { firstName, fullName, email, siteUrl } = personalInfo;
const currentEmployer = jobs.find((job) => job.current) || jobs[0];
const previousEmployers = jobs.filter(
  (job) => job.company != currentEmployer.company,
);

const Animate = (props: { children: ReactNode }) => {
  return (<motion.div variants={getVariant('zoomIn')} {...props} />)
}

const ExternalLink = (props: IStyledLinkLinkProps) => {
  return (
    <StyledLink
      {...props}
      target="_blank"
      typographyProps={{
        variant: 'subtitle2',
      }}
    />
  );
};

const MoreLink = ({ to, label }: { to: string; label: string }) => {
  return (
    <>
      <StyledNavLink to={to} label="Learn More">
        <StyledButton variant="text">
          <StyledTypography
            fontVariant="titleLight"
            capitalized={true}
            bold={true}>
            {label}
          </StyledTypography>
        </StyledButton>
      </StyledNavLink>
    </>
  );
};

const InfoBar = () => {
  const resumePageRoute = useMatch(`/resume`);
  const { scaleFactor } = useBreakpoints();

  return (
    <>
      <MotionContainerStack open initial="initial" spacing={6 / scaleFactor}>
        <Animate>
          <Stack spacing={3 / scaleFactor} alignItems="center">
            <ProfilePicture size="veryLarge" />
            <StyledTypography fontVariant="title" capitalized={true}>
              {fullName}
            </StyledTypography>
            <StyledLink
              to={`mailto:${email}`}
              bold={true}
              capitalized={true}
              label={`${email} 📧`}
              typographyProps={{
                variant: 'overline',
                color: 'text.disabled',
                letterSpacing: '0.15em',
              }}
            />
          </Stack>
        </Animate>
        <Divider />
        <Animate>
          <Stack spacing={3 / scaleFactor}>
            <StyledTypography
              variant="button"
              fontVariant="titleLight"
              bold={true}
              capitalized={true}>
              About
            </StyledTypography>
            <Typography variant="subtitle2" paragraph>
              Hi👋, I&apos;m {firstName}. I like building things. I am a{' '}
              <ExternalLink
                to="https://svitvasad.ac.in/"
                label="SVIT, Vasad - GTU"
              />{' '}
              graduate, and currently working as a {currentEmployer.position} at{' '}
              <ExternalLink
                to={currentEmployer.link}
                label={currentEmployer.company}
              />
              . Before {currentEmployer.company} I was at{' '}
              {previousEmployers.map((job, index) => (
                <Fragment key={job.company}>
                  {index === previousEmployers.length - 1 && 'and '}
                  <ExternalLink to={job.link} label={job.company} />
                  {index < previousEmployers.length - 1 && ', '}
                </Fragment>
              ))}
              .
            </Typography>
            <MoreLink
              to={resumePageRoute ? '/about' : '/resume'}
              label={resumePageRoute ? 'About me' : 'Know More'}
            />
          </Stack>
        </Animate>
        <Divider />
        <Animate>
          <Stack direction="row">
            <ContactIcons />
          </Stack>
          <Typography
            display="inline"
            variant="overline"
            sx={{
              color: 'text.disabled',
            }}>
            &copy; {fullName}{' '}
            <StyledNavLink
              to="/"
              label={siteUrl}
              typographyProps={{
                variant: 'overline',
              }}
              disableActiveStyle={true}
            />
            .
          </Typography>
        </Animate>
      </MotionContainerStack>
    </>
  );
};

export default InfoBar;
