import Analytics from './components/Template/Analytics';
import AppRoutes from './routes';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import Navigation from './components/Template/Navigation';
import ProvideAppTheme from './context/AppThemeContext';
import React from 'react';
import { StyledEngineProvider } from '@mui/material/styles';

const { PUBLIC_URL } = process.env;


const AppModule = () => {
  return (
    <>
      <AppRoutes />
    </>
  );
};

const App = () => {
  return (
    <>
      <HelmetProvider>
        <ProvideAppTheme>
          <CssBaseline>
            <StyledEngineProvider injectFirst>
              <BrowserRouter>
                <Analytics />
                <Navigation />
                <AppModule />
              </BrowserRouter>
            </StyledEngineProvider>
          </CssBaseline>
        </ProvideAppTheme>
      </HelmetProvider>
    </>
  );
};

export default App;
