import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import React from 'react';
import TwitterIcon from '@mui/icons-material/Twitter';
import personalInfo from './personalInfo';

// import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';

// import InstagramIcon from '@mui/icons-material/Instagram';





interface IContact {
  link: string;
  label: string;
  icon: React.ReactNode;
}

const contacts: IContact[] = [
  {
    link: personalInfo.linkedInUrl,
    label: 'LinkedIn',
    icon: <LinkedInIcon />,
  },
  {
    link: personalInfo.githubUrl,
    label: 'Github',
    icon: <GitHubIcon />,
  },
  {
    link: personalInfo.twitterUrl,
    label: 'Twitter',
    icon: <TwitterIcon />,
  },
  // {
  //   link: personalInfo.instagramUrl,
  //   label: 'Instagram',
  //   icon: <InstagramIcon />,
  // },
  // {
  //   link: personalInfo.facebookUrl,
  //   label: 'Facebook',
  //   icon: <FacebookRoundedIcon />,
  // },
  {
    link: `mailto:${personalInfo.email}`,
    label: 'Email',
    icon: <EmailRoundedIcon />,
  },
];

export default contacts;
