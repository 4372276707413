interface IPersonalInfo {
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    siteUrl: string;
    githubUrl: string;
    facebookUrl: string;
    instagramUrl: string;
    linkedInUrl: string;
    twitterUrl: string;
}

const personalInfo: IPersonalInfo = {
    firstName: 'Nilesh',
    lastName: 'Moradiya',
    fullName: 'Nilesh Moradiya',
    email: 'nileshmoradiya_coder@outlook.com',
    siteUrl: 'nileshmoradiya.com',
    githubUrl: 'https://github.com/nileshmoradiya',
    facebookUrl: 'https://www.facebook.com/NnileshH',
    instagramUrl: 'https://www.instagram.com/nilesh_moradiya',
    linkedInUrl: 'https://www.linkedin.com/in/nilesh-moradiya',
    twitterUrl: 'https://twitter.com/Nilesh_Moradiya'
}

export default personalInfo;