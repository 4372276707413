import {
  Box,
  CircularProgress,
  Divider,
  Paper,
  Skeleton,
  Theme,
} from '@mui/material';
import React, { ReactNode } from 'react';
import StyledTypography, {
  IStyledTypographyProps,
} from '../components/StyledTypography';

import { MotionContainerStack } from '../components/Animation/MotionContainerStack';
import getVariant from '../components/Animation/getVariant';
import { motion } from 'framer-motion';
import useBreakpoints from '../hooks/useBreakpoints';

interface IPageProps {
  header: ReactNode;
  headerProps?: IStyledTypographyProps;
  subHeader?: ReactNode;
  subHeaderProps?: IStyledTypographyProps;
  children: ReactNode;
}

const PageContentLayout = ({
  header,
  headerProps,
  subHeader,
  subHeaderProps,
  children,
}: IPageProps) => {
  const { scaleFactor } = useBreakpoints();
  return (
    <>
      <Paper variant="outlined">
        <MotionContainerStack
          open
          initial="initial"
          sx={{ px: 6 / scaleFactor, py: 6 / scaleFactor }}
          spacing={2 / scaleFactor}>
          {typeof header === 'string' && (
            <Box component={motion.div} variants={getVariant('fadeInRight')}>
              <StyledTypography
                fontVariant="title"
                aria-label="title"
                capitalized={true}
                hoverEffect={true}
                {...headerProps}>
                {header}
              </StyledTypography>
            </Box>
          )}

          {typeof header !== 'string' && <>{header}</>}
          {subHeader && typeof subHeader === 'string' && (
            <Box component={motion.div} variants={getVariant('fadeInRight')}>
              <StyledTypography
                fontVariant="subtitle"
                variant="overline"
                aria-label="subtitle"
                capitalized={true}
                {...subHeaderProps}>
                {subHeader}
              </StyledTypography>
            </Box>
          )}

          {subHeader && typeof subHeader !== 'string' && <>{subHeader}</>}
        </MotionContainerStack>
        <Divider />
        <Box sx={{ p: 6 / scaleFactor }}>{children}</Box>
      </Paper>
    </>
  );
};

export default PageContentLayout;

const PageContentLayoutPlaceholder = () => {
  const { sm } = useBreakpoints();
  return (
    <>
      <PageContentLayout
        header={
          <>
            <Skeleton height={36} />
          </>
        }
        subHeader={
          <>
            <Skeleton height={30} />
          </>
        }>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height={sm ? '71vh' : '55vh'}>
          <CircularProgress
            sx={{
              color: (theme: Theme) => theme.palette.text.disabled,
            }}
          />
        </Box>
      </PageContentLayout>
    </>
  );
};

export { PageContentLayoutPlaceholder };
