import {
  AppBar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
} from '@mui/material';
import React, { Fragment, ReactNode } from 'react';

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { MotionContainerStack } from '../Animation/MotionContainerStack';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import ProfilePicture from '../ProfilePicture';
import StyledIconButton from '../StyledIconButton';
import StyledNavLink from '../StyledNavLink';
import StyledTypography from '../StyledTypography';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import getVariant from '../Animation/getVariant';
import { motion } from 'framer-motion';
import personalInfo from '../../data/personalInfo';
import routes from '../../data/routes';
import { useAppTheme } from '../../context/AppThemeContext';
import useBreakpoints from '../../hooks/useBreakpoints';

const indexLinks = routes.filter((link) => link.index);
const nonIndexLinks = routes.filter((link) => !link.index);

const ThemeToggler = ({ onClick }: { onClick?: () => void }) => {
  const { theme, toggleMode } = useAppTheme();
  const onToggleClick = async () => {
    toggleMode();
    if (onClick) {
      onClick();
    }
  };
  return (
    <StyledIconButton onClick={onToggleClick} aria-label="Switch Theme">
      {theme.palette.mode === 'dark' && <WbSunnyIcon />}
      {theme.palette.mode === 'light' && <NightsStayIcon />}
    </StyledIconButton>
  );
};

const NavLinkAnimation = ({ children }: { children: ReactNode }) => {
  return (
    <Box component={motion.div} variants={getVariant('bounceIn')}>
      {children}
    </Box>
  );
};

const DesktopNavigation = () => {
  return (
    <MotionContainerStack
      direction="row"
      spacing={4}
      alignItems="center"
      open
      initial="initial">
      {indexLinks.map((indexLink) => (
        <NavLinkAnimation key={indexLink.label}>
          <StyledNavLink
            to={indexLink.path}
            label={indexLink.label}
            disableActiveStyle={true}>
            <StyledTypography
              variant="button"
              display="inline"
              bold={true}
              capitalized={true}
              spaced={true}
              hoverEffect={true}>
              {indexLink.label}
            </StyledTypography>
          </StyledNavLink>
        </NavLinkAnimation>
      ))}
      {nonIndexLinks.map((nonIndexLink) => (
        <NavLinkAnimation key={nonIndexLink.label}>
          <Divider orientation="vertical" />
          <StyledNavLink to={nonIndexLink.path} label={nonIndexLink.label}>
            <StyledTypography
              variant="overline"
              display="inline"
              capitalized={true}
              spaced={true}
              hoverEffect={true}>
              {nonIndexLink.label}
            </StyledTypography>
          </StyledNavLink>
        </NavLinkAnimation>
      ))}
      <ThemeToggler />
    </MotionContainerStack>
  );
};

const MobileMenu = () => {
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(
    null,
  );
  const isMenuOpen = Boolean(anchorElement);

  const openMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const onNavigationClick = () => {
    setAnchorElement(null);
  };

  return (
    <>
      <IconButton onClick={openMenuClick}>
        <MenuRoundedIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorElement}
        open={isMenuOpen}
        onClose={onNavigationClick}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {indexLinks.map((indexLink) => (
          <StyledNavLink
            to={indexLink.path}
            label={indexLink.label}
            disableActiveStyle={true}
            key={indexLink.label}>
            <MenuItem onClick={onNavigationClick}>
              <StyledTypography
                variant="button"
                display="inline"
                bold={true}
                capitalized={true}
                spaced={true}
                hoverEffect={true}>
                {indexLink.label}
              </StyledTypography>
            </MenuItem>
          </StyledNavLink>
        ))}
        <Divider orientation="horizontal" variant="middle" />
        {nonIndexLinks.map((nonIndexLink) => (
          <StyledNavLink
            to={nonIndexLink.path}
            label={nonIndexLink.label}
            key={nonIndexLink.label}>
            <MenuItem onClick={onNavigationClick}>
              <StyledTypography
                variant="overline"
                display="inline"
                capitalized={true}
                spaced={true}
                hoverEffect={true}>
                {nonIndexLink.label}
              </StyledTypography>
            </MenuItem>
          </StyledNavLink>
        ))}
        <Divider orientation="horizontal" variant="middle" />
        <Box display="flex" flexDirection="row" justifyContent="center">
          <ThemeToggler onClick={onNavigationClick} />
        </Box>
      </Menu>
    </>
  );
};

const MobileNavigation = () => {
  return (
    <>
      <StyledTypography
        variant="button"
        bold={true}
        capitalized={true}
        spaced={true}
        hoverEffect={true}
        sx={{ flexGrow: 1 }}>
        {personalInfo.fullName}
      </StyledTypography>
      <Box flexDirection="row-reverse" sx={{ py: 1 }}>
        <ProfilePicture size="medium" />
      </Box>
      <MobileMenu />
    </>
  );
};

const Navigation = () => {
  const { md: mdUp = true } = useBreakpoints('up');
  return (
    <>
      <AppBar
        position="sticky"
        variant="outlined"
        elevation={0}
        sx={{
          alignItems: mdUp ? 'center' : undefined,
        }}>
        <Toolbar variant="dense">
          {!mdUp && <MobileNavigation />}
          {mdUp && <DesktopNavigation />}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navigation;
