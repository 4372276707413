import { useMediaQuery, useTheme } from "@mui/material";

const useBreakpoints = (filter: 'up' | 'down' | 'only' = 'down') => {
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints[filter]('sm'));
    const md = useMediaQuery(theme.breakpoints[filter]('md'));
    const lg = useMediaQuery(theme.breakpoints[filter]('lg'));
    const scaleFactor = sm ? 3 : md ? 2 : 1;
    return {
        sm: sm,
        md: md,
        lg: lg,
        scaleFactor: scaleFactor,
    };
};

export default useBreakpoints;
