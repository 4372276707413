import { IconButton, IconButtonProps, styled } from '@mui/material';

import React from 'react';
import useDeviceConfiguration from '../hooks/useDeviceConfiguration';

const StyledIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => {
  const { hasTouchScreen } = useDeviceConfiguration();
  return {
    '&:hover': hasTouchScreen
      ? {}
      : {
          color: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
  };
});

export default StyledIconButton;
