import { Box, Container, Divider, Grid, Hidden, Slide } from '@mui/material';

import InfoBar from '../components/Template/InfoBar';
import { Outlet } from 'react-router';
import React from 'react';
import Sticky from 'react-stickynode';
import { TransitionGroup } from 'react-transition-group';
import useBreakpoints from '../hooks/useBreakpoints';
import { useLocation } from 'react-router-dom';

const MainLayout = () => {
  const { scaleFactor } = useBreakpoints();
  const location = useLocation();
  return (
    <Box
      sx={{
        my: 6 / scaleFactor,
      }}>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Hidden mdDown>
            <Grid item xs={12} md={5} lg={4}>
              <Sticky top={98}>
                <InfoBar />
              </Sticky>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={7} lg={8}>
            <TransitionGroup>
              <Slide
                in={true}
                timeout={300}
                direction="left"
                key={location.pathname}>
                <Box>
                  <Outlet />
                </Box >
              </Slide>
            </TransitionGroup>
          </Grid>
          <Hidden mdUp>
            <Grid item xs={12} md={5} lg={4}>
              <Divider
                sx={{
                  mb: 2,
                }}
              />
              <InfoBar />
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </Box >
  );
};

export default MainLayout;
