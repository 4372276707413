import { Avatar, Skeleton } from '@mui/material';
import React, { useState } from 'react';

import personalInfo from '../data/personalInfo';

const { fullName } = personalInfo;

const { REACT_APP_CONTENT_CDN_URL } = process.env;

export type Size = 'medium' | 'veryLarge';

export const AvatarSizeInPixels = {
  medium: 48,
  veryLarge: 160,
};

interface IProfilePictureProps {
  size?: Size;
}

const getSizes = (sizeType: Size): { avatarSize: number } => {
  return {
    avatarSize: AvatarSizeInPixels[sizeType],
  };
};

const ProfilePicture = (props: IProfilePictureProps) => {
  const url = '/images/me.jpeg'//`${REACT_APP_CONTENT_CDN_URL}/site-content/me.jpg`;
  const { size = 'medium' } = props;
  const { avatarSize } = getSizes(size);
  const [isMediaLoading, setIsMediaLoading] = useState<boolean>(true);
  const onImageLoaded = () => {
    setIsMediaLoading(false);
  };

  return (
    <>
      {isMediaLoading ? (
        <Skeleton
          animation="wave"
          variant="circular"
          width={avatarSize}
          height={avatarSize}
        />
      ) : (
        <Avatar
          alt={fullName}
          src={url}
          sx={{
            width: avatarSize,
            height: avatarSize,
          }}
          style={isMediaLoading ? { display: 'none' } : {}}>
          N M
        </Avatar>
      )}
      <img
        src={url}
        alt={fullName}
        width={avatarSize}
        height={avatarSize}
        style={{ display: 'none' }}
        onLoad={onImageLoaded}></img>
    </>
  );
};

export default ProfilePicture;
