import React, { Suspense, lazy } from 'react';

import MainLayout from '../layout/MainLayout';
import { PageContentLayoutPlaceholder } from '../layout/PageContentLayout';
import { paths } from './paths';
import { useRoutes } from 'react-router-dom';

// eslint-disable-next-line react/display-name
const Loadable = (Component: React.ElementType) => (props: any) => {
  return (
    <Suspense fallback={<PageContentLayoutPlaceholder />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: paths.ROOT_PATH,
      element: <MainLayout />,
      children: [
        {
          path: paths.ROOT_PATH,
          element: <Home />,
        },
        {
          path: paths.about,
          element: <About />,
        },
        {
          path: paths.resume,
          element: <Resume />,
        },
        {
          path: paths.projects,
          element: <Projects />,
        },
        {
          path: paths.stats,
          element: <Stats />,
        },
        {
          path: paths.contact,
          element: <Contact />,
        },
        {
          path: '*',
          element: <NotFound />,
        },
      ],
    },
  ]);
}

const NotFound = Loadable(lazy(() => import('../pages/NotFound')));
const Home = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Resume = Loadable(lazy(() => import('../pages/Resume')));
const Projects = Loadable(lazy(() => import('../pages/Projects')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Stats = Loadable(lazy(() => import('../pages/Stats')));
