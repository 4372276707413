export interface IJob {
  company: string;
  position: string;
  link: string;
  dateRange: string;
  abstract?: string;
  points: string[];
  current?: boolean;
}

const jobs: IJob[] = [
  {
    company: 'Insight',
    position: 'Architect',
    link: 'https://www.insight.com',
    dateRange: 'February 2023 - Till now',
    abstract: 'Cloud Apps and Integrations',
    points: [],
    current: true,
  },
  {
    company: 'IMRIEL',
    position:
      'Solution Architect | Technical Architect | Software Engineer | DevOps Engineer',
    link: 'https://imriel.com',
    dateRange: 'November 2015 - February 2023',
    abstract: 'Architect & Develop applications',
    points: [
      'Transform monolithic apps to/with micro/mini service/serverless architectures.',
      'Data Modelling, Architecting, developing end to end enterprise-grade apps with cloud and serverless technologies.',
      'Lead teams, manage internal repositories, Peer-reviewing, managing legacy apps, maintain clean coding standards.',
      'Leading, Mentoring, and assisting with teams as well as Peer-reviewing code for other team members to ensure the highest possible code quality and clean coding standards in software products.',
      'Advising on innovative software development practices and providing technical assistance in dealing with client matters.',
      'Researching and evaluating new development tools and software products to increase programming capabilities.',
      'Scalable Integration projects between various CMS like MSD and Salesforce, Yardi using Serverless technology for C&W the real-estate giant.',
      'Developed a virtual carpet visualizer using ML/AI and Image Processing(https://loom-trial.myshopify.com/pages/visualizer).',
      'Designing Database Model and Application Solution to manage a repository of financial real-estate transactions in APAC, EMEA and America regions for in-depth trend analysis.',
      'Design end to end automation with CI/CD and DevOps processes using the latest tools and developing VNext build & release tasks.',
      'Assisting in designing, developing, analyzing, testing, debugging, and executing modern Web Applications.',
      'Determining user requirements, including project budgets and timelines as well as providing consultative services to clients and project team members on technical issues.',
      'Preparing accurate reports to document design flaws and bugs as well as modifying existing software or system to correct errors and improve performance.',
      'Developed custom importing tool libraries.',
      'Convert monolithic applications to micro/mini services.',
    ],
    current: false,
  },
  // {
  //   company: 'IMRIEL',
  //   position: 'Software Engineer | DevOps Engineer',
  //   link: 'https://imriel.com',
  //   dateRange: 'November 2015 - February 2018',
  //   abstract: 'Develop applications for CBRE and C&W.',
  //   points: [
  //     'Designing Database Model and Application Solution to manage a repository of financial real-estate transactions in APAC, EMEA and America regions for in-depth trend analysis.',
  //     'Design end to end automation with CI/CD and DevOps processes using the latest tools and developing VNext build & release tasks.',
  //     'Assisting in designing, developing, analyzing, testing, debugging, and executing modern Web Applications.',
  //     'Determining user requirements, including project budgets and timelines as well as providing consultative services to clients and project team members on technical issues.',
  //     'Preparing accurate reports to document design flaws and bugs as well as modifying existing software or system to correct errors and improve performance.',
  //     'Developed custom importing tool libraries.',
  //     'Convert monolithic applications to micro/mini services.',
  //   ],
  //   current: false,
  // },
  {
    company: 'TechGrains',
    position: 'Sr. Software Engineer',
    link: 'https://www.techgrains.com/',
    dateRange: 'September 2013 - October 2015',
    abstract: 'Develop and Support applications for hospitality industry.',
    points: [
      'Developed a POS System for Da-Pizza Planet chain with various hardware integration.',
      'Developed Web APIs for internal product GoSurvey(http://gosurvey.in) and ShareExpense(http://shareexpense.in/) mobile apps for iOS, Android and Web clients.',
      'Written, tested, and debugged Web API, Web applications and desktop applications.',
      'Handled front-end and back-end aspect of Web applications.',
      'Coordinated with clients for complex software installations and issue.',
      'Perform code review, improve coding standards and ensuring software quality.',
    ],
  },
  {
    company: 'Promact',
    position: 'Jr. Software Engineer',
    link: 'https://promactinfo.com',
    dateRange: 'July 2012 - August 2013',
    abstract: 'Develop and Support applications for portfolio of applications.',
    points: [
      'Developed Web applications using MVC and Web API.',
      'Developed test plans, identified issues, and modified programs.',
      'Researched, analyzed and modified existing systems.',
      'Supported back-office desktop applications for www.viagogo.com.',
      'Enhanced and deployed reporting tool based on Google Analytics.',
      'Debugging and fixing issues in existing applications.',
    ],
  },
];

export default jobs;
