import { BoxProps, Stack, StackProps } from '@mui/material';

import React from 'react';
import { motion } from 'framer-motion';
import { varWrapEnter } from './variants';

type STACK_EXTENSION_PROPS =
  | 'children'
  | 'spacing'
  | 'sx'
  | 'direction'
  | 'justifyContent'
  | 'alignItems';

interface MotionContainerStackProps
  extends Pick<StackProps, STACK_EXTENSION_PROPS> {
  initial?: boolean | string;
  open?: boolean;
}

export function MotionContainerStack({
  open,
  children,
  spacing,
  ...other
}: MotionContainerStackProps) {
  return (
    <>
      <Stack
        spacing={spacing || 2}
        component={motion.div}
        initial={false}
        animate={open ? 'animate' : 'exit'}
        variants={varWrapEnter}
        {...other}>
        {children}
      </Stack>
    </>
  );
}
