import { NavLink, NavLinkProps } from 'react-router-dom';
import { Typography, TypographyProps, useTheme } from '@mui/material';

import React from 'react';
import useDeviceConfiguration from '../hooks/useDeviceConfiguration';

interface IStyledLinkLinkProps extends NavLinkProps {
  to: string;
  label: string;
  capitalized?: boolean;
  bold?: boolean;
  bottomBorder?: boolean;
  typographyProps?: Omit<TypographyProps, 'children'>;
  disableActiveStyle?: boolean;
  children?: JSX.Element;
}

const StyledNavLink = ({
  to,
  label,
  capitalized,
  bold,
  bottomBorder = true,
  typographyProps,
  children,
  disableActiveStyle = false,
  ...rest
}: IStyledLinkLinkProps) => {
  const theme = useTheme();
  const { hasTouchScreen } = useDeviceConfiguration();
  const transformedLabel = capitalized
    ? label?.toString()?.toUpperCase()
    : label;
  const {
    style: typographyStyle,
    sx: typographySx,
    ...restOfTypographyProps
  } = {
    ...typographyProps,
  };
  const overriddenStyle: React.CSSProperties = {
    transition: 'color 0.2s ease, border-bottom-color 0.2s ease',
    borderBottom: bottomBorder
      ? 'dotted 1px rgba(160, 160, 160, 0.65)'
      : undefined,
    fontWeight: bold ? 'bold' : undefined,
  };
  return (
    <NavLink
      {...rest}
      to={to}
      style={({ isActive }) =>
        isActive && !disableActiveStyle
          ? {
            textDecoration: 'underline',
            color: theme.palette.primary.main,
          }
          : {
            color: 'inherit',
            textDecoration: 'none',
          }
      }
    >
      {children &&
        React.Children.map(children, (child) =>
          React.cloneElement(child, {
            style: {
              ...child.props.style,
              '&:hover': hasTouchScreen
                ? {}
                : {
                  color: theme.palette.primary.main,
                },
            },
          }),
        )}
      {!children && (
        <Typography
          {...restOfTypographyProps}
          component="span"
          style={{
            ...typographyStyle,
            ...overriddenStyle,
          }}
          sx={{
            ...typographySx,
            '&:hover': hasTouchScreen
              ? {}
              : {
                color: theme.palette.primary.main,
              },
          }}>
          {transformedLabel}
        </Typography>
      )}
    </NavLink>
  );
};

export default StyledNavLink;
