import {
  Link,
  LinkProps,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import React, { ReactNode, useState } from 'react';

import useDeviceConfiguration from '../hooks/useDeviceConfiguration';

export interface IStyledLinkLinkProps extends Omit<LinkProps, 'href'> {
  to: string;
  label: string;
  capitalized?: boolean;
  bold?: boolean;
  bottomBorder?: boolean;
  typographyProps?: Omit<TypographyProps, 'children'>;
  children?: JSX.Element;
}

const StyledLink = ({
  to,
  label,
  capitalized,
  bold,
  bottomBorder = true,
  typographyProps,
  children,
  ...rest
}: IStyledLinkLinkProps) => {
  const theme = useTheme();
  const { hasTouchScreen } = useDeviceConfiguration();
  const transformedLabel = (capitalized ? label?.toUpperCase() : label) || to;
  const {
    style: typographyStyle,
    sx: typographySx,
    ...restOfTypographyProps
  } = {
    ...typographyProps,
  };
  const overriddenStyle: React.CSSProperties = {
    transition: 'color 0.2s ease, border-bottom-color 0.2s ease',
    borderBottom: bottomBorder
      ? 'dotted 1px rgba(160, 160, 160, 0.65)'
      : undefined,
    fontWeight: bold ? 'bold' : undefined,
    cursor: 'pointer',
  };
  return (
    <Link
      {...rest}
      href={to}
      style={{
        color: 'inherit',
        textDecoration: 'none',
      }}
      rel="noopener">
      {children &&
        React.Children.map(children, (child) =>
          React.cloneElement(child, {
            style: {
              ...child.props.style,
              ...overriddenStyle,
              '&:hover': hasTouchScreen
                ? {}
                : {
                    color: theme.palette.primary.main,
                  },
            },
          }),
        )}
      {!children && (
        <Typography
          {...restOfTypographyProps}
          style={{
            ...typographyStyle,
            ...overriddenStyle,
          }}
          sx={{
            ...typographySx,
            '&:hover': hasTouchScreen
              ? {}
              : {
                  color: theme.palette.primary.main,
                },
          }}
          component="span">
          {transformedLabel}
        </Typography>
      )}
    </Link>
  );
};

export default StyledLink;
