import { ThemeOptions } from "@mui/material";

const lightTheme: ThemeOptions = {
  palette: {
    primary: {
      main: '#2e59ba'
    },
    background: {
      default: '#f5f5f5',
    },
    mode: 'light'
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
          /* Make scroll overlay*/
        body {
              overflow-y:overlay;
          }
        /* Ref: https://www.w3schools.com/howto/howto_css_custom_scrollbar.asp*/
        /* Scrollbar width */
        ::-webkit-scrollbar {
          width: 8px;
          height:8px;
        }
        /* Scrollbar Track */
        ::-webkit-scrollbar-track {
          background: #e0e0e0;
          border-radius: 4px;
        }
        /* Scrollbar Handle */
        ::-webkit-scrollbar-thumb {
          background: #bdbdbd;
          border-radius: 4px;
        }
        /* Scrollbar Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #9e9e9e;
          border-radius: 4px;
        }
        `,
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          color: '#212121'
        }
      }
    }
  }
};

const darkTheme: ThemeOptions = {

  palette: {
    primary: {
      // main: '#2e7d32' //Green
      main: '#2196f3'
    },
    background: {
      default: '#090C10',
      paper: '#0D1117'
      // default: '#00172F',
      // paper: '#001C2E'
    },
    mode: 'dark'
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
          /* Make scroll overlay*/
        body {
              overflow-y:overlay;
          }
        /* Ref: https://www.w3schools.com/howto/howto_css_custom_scrollbar.asp*/
        /* Scrollbar width */
        ::-webkit-scrollbar {
          width: 8px;
          height:8px;
        }
        /* Scrollbar Track */
        ::-webkit-scrollbar-track {
          background: #2B2B2B;
          border-radius: 4px;
        }
        /* Scrollbar Handle */
        ::-webkit-scrollbar-thumb {
          background: #626262;
          border-radius: 4px;
        }
        /* Scrollbar Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #959595;
          border-radius: 4px;
        }
        `,
    },
    // MuiAppBar: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: '#0D1117',
    //       color: '#FFFFFF'
    //     }
    //   }
    // }
  }
};

export default lightTheme;
export { darkTheme, lightTheme };
