import { Button, ButtonProps, styled } from '@mui/material';

import React from 'react';
import useDeviceConfiguration from '../hooks/useDeviceConfiguration';

const StyledButton = styled(Button)<ButtonProps>(({ theme }) => {
  const { hasTouchScreen } = useDeviceConfiguration();
  return {
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    border: '1px solid',
    borderRadius: 0,
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
    borderColor: theme.palette.action.focus,
    '&:hover': hasTouchScreen
      ? {}
      : {
          color: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
  };
});

export default StyledButton;
