import { Box, Link } from '@mui/material';
import React, { ReactNode } from 'react';

import { MotionContainerStack } from '../Animation/MotionContainerStack';
import StyledIconButton from '../StyledIconButton';
import contacts from '../../data/contact';
import getVariant from '../Animation/getVariant';
import { motion } from 'framer-motion';

const ContactIcon = ({
  children,
  label,
}: {
  children: ReactNode;
  label: string;
}) => {
  return (
    <>
      <StyledIconButton aria-label={label}>{children}</StyledIconButton>
    </>
  );
};

const ContactIcons = () => {
  return (
    <>
      <MotionContainerStack open initial="initial" direction="row">
        {contacts.map((contact) => (
          <Box
            key={contact.label}
            component={motion.div}
            variants={getVariant('fadeInRight')}>
            <Link

              href={contact.link}
              target="_blank"
              rel="noopener">
              <ContactIcon label={contact.label}>{contact.icon}</ContactIcon>
            </Link>
          </Box>
        ))}
      </MotionContainerStack>
    </>
  );
};

export default ContactIcons;
