import React, { useEffect } from 'react';

import GA4React from 'ga-4-react';
import { useLocation } from 'react-router-dom';

const { NODE_ENV, REACT_APP_GA_TRACKING_ID } = process.env;

let ga4react: GA4React;
if (NODE_ENV === 'production' && REACT_APP_GA_TRACKING_ID) {
  ga4react = new GA4React(REACT_APP_GA_TRACKING_ID);
}

const Analytics = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    if (ga4react) {
      (async (scopedPath) => {
        const isGaInitialized = GA4React.isInitialized();
        if (!isGaInitialized) {
          await ga4react.initialize();
        }
        ga4react.pageview(scopedPath);
      })(pathname);
    }
  }, [pathname]);

  return <></>;
};

export default Analytics;
