import { PaletteMode, useMediaQuery } from '@mui/material';
import React, { ReactNode, useLayoutEffect, useMemo } from 'react';
import {
  Theme,
  ThemeOptions,
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from '@mui/material/styles';
import { createContext, useContext, useState } from 'react';
import { darkTheme, lightTheme } from '../data/theme';

import cookie from 'js-cookie';

const themes: { light: ThemeOptions; dark: ThemeOptions } = {
  light: lightTheme,
  dark: darkTheme,
};

const COOKIES_THEME_MODE = 'v1-theme-mode';

const getDefaultMode = (): PaletteMode => {
  const presetCookiesThemeMode = cookie.get(COOKIES_THEME_MODE);

  if (presetCookiesThemeMode) {
    return presetCookiesThemeMode === 'dark' ? 'dark' : 'light';
  }
  const hours = new Date().getHours();

  return hours >= 19 || hours <= 7 ? 'dark' : 'light';
};

const getTheme = (mode: PaletteMode): Theme => {
  return responsiveFontSizes(createTheme(themes[mode]));
};

interface IAppThemeContext {
  theme: Theme;
  toggleMode: () => void;
}

const defaultContext = {
  theme: getTheme(getDefaultMode()),
  toggleMode: () => {},
};

export const AppThemeContext = createContext<IAppThemeContext>(defaultContext);
AppThemeContext.displayName = 'AppThemeContext';

export const useAppTheme = () => {
  return useContext(AppThemeContext);
};

function provideAppTheme(): IAppThemeContext {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState<PaletteMode>(getDefaultMode());
  const theme = useMemo(() => getTheme(mode), [mode]);
  // const [theme, setTheme] = useState<Theme>(getTheme(mode));

  const toggleMode = () => {
    setMode((previousMode) => {
      const newMode = previousMode === 'dark' ? 'light' : 'dark';
      cookie.set(COOKIES_THEME_MODE, newMode, {
        expires: 7,
      });
      return newMode;
    });
  };

  // useEffect(() => {
  //   setTheme(getTheme(mode));
  // }, [mode]);

  useLayoutEffect(() => {
    if (prefersDarkMode && !cookie.get(COOKIES_THEME_MODE)) {
      setMode('dark');
    }
  }, [prefersDarkMode]);
  return { theme: theme, toggleMode: toggleMode };
}

interface IProvideAppThemeProps {
  children: ReactNode;
}

const ProvideAppTheme = ({ children }: IProvideAppThemeProps) => {
  const themeContext = provideAppTheme();
  return (
    <AppThemeContext.Provider value={themeContext}>
      <ThemeProvider theme={themeContext.theme}>{children}</ThemeProvider>
    </AppThemeContext.Provider>
  );
};

export default ProvideAppTheme;
