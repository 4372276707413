import React, { useState } from 'react';
import { Typography, TypographyProps, useTheme } from '@mui/material';

import { SxProps } from '@mui/system';
import useDeviceConfiguration from '../hooks/useDeviceConfiguration';

const LETTER_SPACING = '0.25em';

type FontVariant = 'normal' | 'title' | 'titleLight' | 'subtitle';

const fontVariantStyles: Record<FontVariant, SxProps> = {
  normal: {},
  title: {
    fontWeight: 900,
    fontSize: '1.5em',
    fontFamily: '"Raleway", Helvetica, sans-serif',
    letterSpacing: LETTER_SPACING,
  },
  titleLight: {
    fontWeight: 800,
    fontSize: '0.8em',
    fontFamily: '"Raleway", Helvetica, sans-serif',
    letterSpacing: LETTER_SPACING,
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '0.7em',
    fontFamily: '"Raleway", Helvetica, sans-serif',
    letterSpacing: LETTER_SPACING,
  },
};

export interface IStyledTypographyProps extends TypographyProps {
  bold?: boolean;
  capitalized?: boolean;
  spaced?: boolean;
  fontVariant?: FontVariant;
  hoverEffect?: boolean;
}

const StyledTypography = ({
  bold = false,
  capitalized = false,
  spaced = false,
  fontVariant = 'normal',
  hoverEffect = false,
  children,
  style: styleProps,
  sx: sxProps,
  ...rest
}: IStyledTypographyProps & TypographyProps) => {
  const theme = useTheme();
  const { hasTouchScreen } = useDeviceConfiguration();
  const fontVariantStyle = fontVariantStyles[fontVariant];
  const overriddenStyle: React.CSSProperties = {
    transition: hoverEffect
      ? 'color 0.2s ease, border-bottom-color 0.2s ease'
      : undefined,
    cursor: hoverEffect ? 'pointer' : undefined,
    fontWeight: bold ? 'bold' : undefined,
    letterSpacing: spaced ? LETTER_SPACING : undefined,
  };
  const capitalizedChildren =
    capitalized && typeof children === 'string'
      ? children?.toString()?.toUpperCase()
      : children;
  return (
    <Typography
      {...rest}
      sx={{
        ...sxProps,
        ...fontVariantStyle,
        '&:hover': hasTouchScreen
          ? {}
          : {
              color: hoverEffect ? theme.palette.primary.main : undefined,
            },
      }}
      style={{ ...styleProps, ...overriddenStyle }}>
      {capitalizedChildren}
    </Typography>
  );
};

export default StyledTypography;
