import { useMediaQuery } from "@mui/material";

const useDeviceConfiguration = () => {
    const hasTouchScreen = useMediaQuery("(pointer: coarse)");
    return {
        hasTouchScreen: hasTouchScreen
    };
};

export default useDeviceConfiguration;
